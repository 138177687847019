@import "../../styles/_variables.scss";

$logo-height: 160px; // Set a base height for the logo
$nav-height: $logo-height + 10px; // Set nav-height equal to logo height plus some padding

$base-title-size: $logo-height * 0.3; // Calculate the title size based on 60% of the logo height
$base-subtitle-size: $base-title-size * 0.3; // Calculate the subtitle size based on 50% of the title size

.Navbar {
    background-color: #000000;
    color: map-get($color, "light");
    font-size: map-get($size, "sm");

    position: fixed;
    top: 0;
    width: 100%;
    z-index: map-get($z-index, "highest");
    height: $nav-height; // Apply the same height to the navbar
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15); // Add drop shadow to the navbar

    .navbar-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        height: $nav-height; // Ensure content aligns with the nav height
    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: right;
        margin-right: 100px;
        width: 30%;
    }

    a {
        display: inline-block;
    }

    .active {
        color: map-get($color, "grey");
    }
}

.Navbar-Offset {
    height: $nav-height; // Ensure the offset div matches the nav height
}

.header-container {
    display: flex;
    align-items: center;
    padding: 10px;
    height: $nav-height; // Match the header-container height to the logo/nav height
}

.logo {
    max-height: $logo-height; // Ensure the logo uses the same height variable
    width: 15vw;
    padding-right: 23px;

}

.title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: $base-title-size; // Set title size to 60% of the logo height
    margin: 0;
    font-weight: bold;
}

.subtitle {
    font-size: $base-subtitle-size; // Set subtitle size to 50% of the title size
    margin: 0;
    font-weight: 300;
    color: #666;
}
